<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Check List</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Check List Name *"
                    v-model="checkList.checkListName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Check List Name'"
                    @blur="$v.checkList.checkListName.$touch()"
                    :is-valid="
                      $v.checkList.checkListName.$dirty
                        ? !$v.checkList.checkListName.$error
                        : null
                    "
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveChecklist()"
            >
              <CIcon name="cil-check-circle" />Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Checklist",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      modelId: "",
      checklistId: "",
      checkList: {
        checklistId: "0",
        modelId: "0",
        checkListName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    checkList: {
      checkListName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.modelId = this.$route.params.modelId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.checklistId = this.$route.params.checklistId;
      this.modelId = this.$route.params.modelId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.checklistId = this.$route.params.checklistId;
      this.modelId = this.$route.params.modelId;
      this.getUser();
    }
  },
  components: { datetime },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Checklists",
        params: {
          subGroupId: this.$route.params.subGroupId,
          modelId: this.$route.params.modelId,
          groupId: this.$route.params.groupId,
        },
      });
    },

    saveChecklist() {
      this.$v.checkList.$touch();
      if (this.$v.checkList.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.checkList));
      } else {
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/save/checklist/"+this.modelId, {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.checkList),
        // })
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/checklist/" +
            this.modelId,
          this.checkList
        ).then((checkList) => {
          console.log(JSON.stringify(checkList));

          this.$router.push({
            name: "Checklists",
            params: {
              subGroupId: this.$route.params.subGroupId,
              modelId: this.$route.params.modelId,
              groupId: this.$route.params.groupId,
            },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/checklist/" +
          this.checklistId
      )
        .then((response) => response.json())
        .then((data) => (this.checkList = data));
    },
  },
  mounted() {},
};
</script>
